import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import BannerMp4 from "../videos/contact.mp4";
import BannerWebm from "../videos/contact.webm";
import BannerOgv from "../videos/contact.ogv";
import BannerCover from "../videos/video_cover_contact.jpg";
import { Helmet } from "react-helmet";
import ReactViewAdobe from "react-adobe-embed";

export default function Terms({ data }) {
    const { termsFileName, termsName, adobeEmbedKey } = data.site.siteMetadata;

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (

        <Layout>

            <Helmet>
                <script src="https://documentcloud.adobe.com/view-sdk/viewer.js" />
            </Helmet>
            <Seo
                title='Terms of Trade | Webfox'
                description="Terms of service for Webfox Developments Limited. Specialists in developing web based solutions for business."
            />

            {/* section1 */}
            <div>
                <section className="layer intro intro-default dark">
                    <video id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </video>

                    <div className="inner">
                        <div className="copy">

                        </div>
                    </div>
                </section>
            </div>

            <section className="layer singlecol transparent">
                <div className="inner">
                    <h1>Terms of Trade</h1>
                    <p>Last updated: March 12, 2023</p>

                    {isMounted && (
                        <ReactViewAdobe
                            previewConfig={{
                                showAnnotationTools: false,
                                showLeftHandPanel: false,
                                showDownloadPDF: false,
                                embedMode: "IN_LINE",
                                showPrintPDF: true,
                            }}
                            config={{
                                clientId: adobeEmbedKey,
                                divId: "terms-pdf",
                                url: `/documents/${termsFileName}`,
                                fileMeta: {
                                    fileName: termsFileName,
                                    title: termsName,
                                },
                            }}
                        />
                    )}

                </div>
            </section>


        </Layout>
    )
}

export const query = graphql`
query TermsInfo {
    site {
      siteMetadata {
        termsFileName
        termsName
        adobeEmbedKey
      }
    }
  }
  
`